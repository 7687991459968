import type { BaseUriBuilderParams } from '../types';
import { brandCodeToBrandNameMapper } from '../constants';
import type { SearchUrlParams } from '../search-uri-builder';

// having this as enum so that this would be a single point change if needed
export enum BasePath {
  Dream = 'locations',
  Venue = 'meetings-events',
}

export type DreamUrlParams = Omit<SearchUrlParams, 'dates' | 'dateRange'> & {
  redeemPts?: boolean;
  location?: LocationCoords;
  bounds?: Bounds;
  geocodeType?: string;
  placeUri?: string | null;
};

type LocationCoords = {
  latitude: number;
  longitude: number;
};

type Bounds = {
  northeast: {
    latitude: number;
    longitude: number;
  };
  southwest: {
    latitude: number;
    longitude: number;
  };
};
/**
 * Returns an OHW formatted search URL
 *
 * @param {boolean}  relative Use relative pathing  (defaults to absolute pathing)
 * @param {string}  locale Language locale (defaults to en)
 * @param {BasePath}  BasePath (optional) dream BasePath ('locations' | 'meetings-events'). Defaults to 'locations'
 * @param {keyof BrandCode}  brandCode (optional) Hilton brand code (defaults to HH)
 *
 *  The following paths map to following search experiences
 *  locations => default locations page
 *  meetings-event => Venue search ( 10+ dream ) experience
 *
 *  The following url parameters apply to locations page (dream pages)
 *
 *  brandName = ''
 *
 *  locale = 'en'
 *
 *  location = ''
 *
 *  displayCurrency = 'LOCAL'
 *
 *  redeemPts = false
 *
 *  The following url parameters apply to meeting-events page (venue pages)
 *
 *  locale = 'en'
 *
 *  location = ''
 *
 *  sortBy
 *
 *  f_amenityIds
 *
 *  f_brandCodes
 *
 *  f_price
 *
 *  f_tripAdvisorRatings
 *
 */
export const dreamUriBuilder = ({
  locale = 'en',
  path = BasePath.Dream,
  brandCode,
  urlParams,
  relative,
  baseUrl,
}: BaseUriBuilderParams<DreamUrlParams, BasePath>) => {
  const {
    displayCurrency,
    redeemPts,
    sessionToken,
    sortBy,
    f_amenityIds,
    f_brandCodes,
    f_price,
    f_tripAdvisorRatings,
    adjoiningRoomStay,
    placeUri,
    placeId,
    location,
    geocodeType,
    bounds,
    query,
    cid,
    content,
  } = urlParams || {};

  // use relative pathing if enabled or if user does not provide baseUrl
  let fullPath =
    relative || (!relative && !baseUrl)
      ? `/${locale}`
      : //if the base URL has a trailing slash, do not append a second one
        `${baseUrl}${baseUrl?.endsWith('/') ? '' : '/'}${locale}`;

  // switch between different dream paths depending on desired experience
  switch (path) {
    case BasePath.Venue:
      fullPath += '/meetings-events/';
      break;
    case BasePath.Dream:
    default:
      fullPath += '/locations/';
  }

  if (placeUri) {
    fullPath += placeUri.endsWith('/') ? placeUri : `${placeUri}/`;
  }

  const isBrandCodeMatchedWithFilter =
    !f_brandCodes || (f_brandCodes.length === 1 && f_brandCodes[0] === brandCode);
  // brandname parameter should be added to the uri slug to dream locations pages
  if (
    brandCode &&
    !!brandCodeToBrandNameMapper[brandCode] &&
    path === 'locations' &&
    isBrandCodeMatchedWithFilter
  ) {
    fullPath += `${brandCodeToBrandNameMapper[brandCode]}/`;
  }

  const params = new URLSearchParams();
  if (path !== BasePath.Venue) {
    if (displayCurrency) params.set('displayCurrency', displayCurrency);
    if (redeemPts) params.append('redeemPts', String(redeemPts));
    if (sortBy) params.append('sortBy', sortBy);
    if (f_amenityIds) params.append('f-amenityIds', f_amenityIds.join(','));
    if (f_brandCodes) params.append('f-brandCodes', f_brandCodes.join(','));
    if (f_price) params.append('f-price', String(f_price));
    if (f_tripAdvisorRatings) params.append('f-tripAdvisorRatings', f_tripAdvisorRatings);
    if (adjoiningRoomStay) params.append('adjoiningRoomStay', String(adjoiningRoomStay));
    if (placeId) params.append('placeId', placeId);
    if (query) params.append('query', query);
    if (location) {
      params.append('coords', `${location.latitude},${location.longitude}`);
      if (geocodeType) params.append('geocodeType', geocodeType);
    }
    if (bounds) {
      params.append(
        'bounds',
        `${bounds.southwest.latitude},${bounds.southwest.longitude},${bounds.northeast.latitude},${bounds.northeast.longitude}`
      );
    }
    if (content) {
      params.append('content', content);
    }
  }

  if (sessionToken) params.append('sessionToken', sessionToken);
  if (cid) params.append('cid', cid);

  const queryParams = params.toString();
  fullPath += queryParams ? `?${queryParams}` : '';
  return fullPath;
};
