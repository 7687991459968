import { getGroupOrTransientSubpath } from './../utils';
import type {
  Room,
  Rates,
  Dates,
  SortByValues,
  BaseUriBuilderParams,
  TripAdvisorRatings,
} from '../types';
import { brandCodeToBrandNameMapper } from '../constants';
import { buildRoomsParams, buildRatesParams, buildDatesParams } from './search-uri-builder.utils';

export type Path =
  | 'go-hilton'
  | 'hgv-max'
  | 'find-hotels'
  | 'go-hilton-find-hotels'
  | 'hgv-max-find-hotels'
  | 'search'
  | 'group-search';

export type SearchUrlParams = {
  adjoiningRoomStay?: boolean;
  availableHotelsOnly?: boolean;
  content?: string;
  dates?: Dates;
  displayCurrency?: string;
  f_amenityIds?: string[];
  f_attributeIds?: string[];
  f_brandCodes?: string[];
  f_price?: [number, number];
  f_tripAdvisorRatings?: TripAdvisorRatings;
  maxPoints?: number | null;
  numAttendees?: number | null;
  numRooms?: number | null;
  placeId?: string | null;
  query?: string;
  rates?: Rates;
  rooms?: Room[];
  sessionToken?: string;
  sortBy?: SortByValues;
  specialRateTokens?: string[];
  cid?: string;
};

/**
 * Returns an OHW formatted search URL
 *
 * @param {SearchUrlParams} urlParams
 * @param {boolean} relative - use relative pathing (defaults to absolute pathing)
 * @param {keyof Locale}  locale Language locale (defaults to en)
 * @param {Path}  path (optional) search path ('go-hilton' | 'find-hotels' | 'go-hilton-find-hotels' | 'search' | 'group-search'). Defaults to 'search'
 * @param {keyof BrandCode}  brandCode (optional) Hilton brand code
 *
 *  The following paths map to following search experiences
 *  go-hilton => search with tmtp options
 *  find-hotels => search zero experience
 *  go-hilton-find-hotels => search zero with tmtp options
 *  search => main search page
 *  group-search => 10+ search experience
 *
 *  The following url parameters apply to find-hotels, search, go-hilton and go-hilton-find-hotels
 *
 *  brandCode
 *
 *  locale
 *
 *  query
 *
 *  placeId
 *
 *  arrivalDate
 *
 *  departureDate
 *
 *  datesFlex
 *
 *  sessionToken
 *
 *  rooms
 *
 *  aarp
 *
 *  corporateAccount
 *
 *  employeeRate
 *
 *  friendsAndFamilyRate
 *
 *  government
 *
 *  groupCode
 *
 *  offerId
 *
 *  ownerVIPRate
 *
 *  ownerHGVRate
 *
 *  pnd
 *
 *  promoCode
 *
 *  senior
 *
 *  travelAgents
 *
 *  tripleA
 *
 *  usePoints
 *
 *  specialRateTokens
 *
 *  requestedRatesOnly
 *
 *  displayCurrency
 *
 *  adjoiningRoomStay
 *
 *  maxPoints
 *
 *  sortBy
 *
 *  f_amenityIds
 *
 *  f_attributeIds
 *
 *  f_brandCodes
 *
 *  f_price
 *
 *  f_tripAdvisorRatings
 *
 *  availableHotelsOnly
 *
 * The following url parameters apply to group search (10+).
 *
 *  query
 *
 *  brandCode
 *
 *  arrivalDate
 *
 *  departureDate
 *
 *  numRooms
 *
 *  numAttendees
 *
 *  sessionToken
 *
 *  placeId
 */
export const searchUriBuilder = ({
  brandCode = 'HH',
  locale = 'en',
  path = 'search',
  urlParams,
  relative,
  baseUrl,
  brandSlug,
  primarySlug,
}: BaseUriBuilderParams<SearchUrlParams, Path>) => {
  const {
    adjoiningRoomStay,
    availableHotelsOnly,
    content,
    dates,
    displayCurrency,
    f_amenityIds,
    f_attributeIds,
    f_brandCodes,
    f_price,
    f_tripAdvisorRatings,
    maxPoints,
    numAttendees,
    numRooms,
    placeId,
    query = '',
    rates,
    rooms,
    sessionToken,
    sortBy,
    specialRateTokens,
    cid,
  } = urlParams || {};

  // use relative pathing if enabled or if user does not provide baseUrl
  let url = relative || (!relative && !baseUrl) ? `/${locale}` : `${baseUrl}/${locale}`;
  const isDateless = !(dates?.arrivalDate || dates?.departureDate);
  const basePath = getGroupOrTransientSubpath({
    numAttendees: numAttendees ?? 0,
    numRooms: numRooms ?? 0,
    transientPath: path,
    groupsPath: 'group-search',
  });

  // switch between different search paths, depending on desired experience
  switch (basePath) {
    case 'go-hilton':
      url += '/go-hilton/search/';
      break;
    case 'hgv-max':
      url += '/hgv-max/search/';
      break;
    case 'find-hotels':
      if (primarySlug) {
        url += `/${primarySlug}`;
      }
      url += '/search';
      if (brandSlug) {
        url += `/${brandSlug}`;
      }
      url += '/find-hotels/';
      break;
    case 'go-hilton-find-hotels':
      url += '/go-hilton/find-hotels/';
      break;
    case 'hgv-max-find-hotels':
      url += '/hgv-max/search/find-hotels/';
      break;
    case 'group-search':
      url += '/group-search/';
      break;
    case 'search':
    default:
      url += '/search/';
  }

  //only append brand if brandcode actually should be its own search page
  if (basePath === 'search' && brandCodeToBrandNameMapper[brandCode])
    url += `${brandCodeToBrandNameMapper[brandCode]}/`;

  //if no url params just return url
  if (!urlParams || !Object.keys(urlParams)) return url;

  const params = new URLSearchParams();
  if (placeId) params.append('placeId', placeId);
  if (dates) buildDatesParams(params, dates);
  // these parameters dont apply to group search (10+)
  if (basePath !== 'group-search') {
    if (rooms) buildRoomsParams(params, numRooms ? numRooms : rooms.length, rooms);
    if (rates) buildRatesParams(params, rates);
    if (specialRateTokens) params.append('specialRateTokens', specialRateTokens.join(','));
    if (displayCurrency) params.append('displayCurrency', displayCurrency);
    if (adjoiningRoomStay) params.append('adjoiningRoomStay', String(adjoiningRoomStay));
    if (isDateless && typeof maxPoints === 'number')
      params.append('maxPoints', maxPoints.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (f_amenityIds) params.append('f-amenityIds', f_amenityIds.join(','));
    if (f_attributeIds && f_attributeIds?.length)
      params.append('f-attributeIds', f_attributeIds.join(','));
    if (f_brandCodes) params.append('f-brandCodes', f_brandCodes.join(','));
    if (f_price) params.append('f-price', f_price.join(','));
    if (f_tripAdvisorRatings) params.append('f-tripAdvisorRatings', f_tripAdvisorRatings);
    if (availableHotelsOnly) params.append('availableHotelsOnly', String(availableHotelsOnly));
  }
  // these parameters only apply to group search (10+ search)
  else {
    if (brandCode) params.append('brandCode', brandCode);
    if (numRooms) params.append('numRooms', numRooms.toString());
    if (numAttendees) params.append('attendee_count', numAttendees.toString());
  }
  // passthrough parameters that apply to all searches
  if (sessionToken) params.append('sessionToken', sessionToken);
  if (cid) params.append('cid', cid);
  if (content) params.append('content', content);

  const queryString = params.toString();

  return `${url}?query=${encodeURIComponent(query)}${queryString ? '&' : ''}${queryString}`;
};
