import type { Dates, Room, Adult, Child, BaseUriBuilderParams } from '../types';
import { format, startOfToday, addDays } from 'date-fns';
import { getGroupOrTransientSubpath } from '../utils';

export type BookRates = {
  aaaRate?: boolean | null;
  aarpRate?: boolean | null;
  corporateCode?: string | null;
  employeeRate?: boolean | null;
  friendsAndFamilyRate?: boolean | null;
  fromId?: string | null;
  governmentRate?: boolean | null;
  groupCode?: string | null;
  offerId?: number | null;
  ownerHGVRate?: boolean | null;
  ownerVIPRate?: boolean | null;
  pnd?: string | null;
  promotionCode?: string | null;
  ratePlanCodes?: string | null;
  redeemPts?: boolean | null;
  roomCategory?: string | null;
  roomTypeCode?: string | null;
  seniorRate?: boolean | null;
  smbRate?: boolean | null;
  travelAgent?: boolean | null;
  travelAgentId?: string | null;
};

export type BookPath = 'book' | 'modify';
export type BookUrlParams = {
  dates?: Dates;
  rates?: BookRates;
  rooms?: Room[];
  numRooms?: number | null;
  numAttendees?: number | null;
  ctyhocn: string;
  displayCurrency?: string;
  adjoiningRoomStay?: boolean;
  additionalQS?: {
    [key: string]: string | boolean | undefined;
  };
};

type BookUrlQS = { [key: string]: undefined | string | number | null | boolean };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function bookUriBuilder({
  locale = 'en',
  path = 'book',
  urlParams,
  relative,
  baseUrl,
  page = 'rooms',
}: BaseUriBuilderParams<BookUrlParams, BookPath> & {
  page?: 'rooms' | 'rates' | 'flexibledates' | 'deeplink' | 'payment';
}): string {
  const urlparts: string[] = [buildBaseUrl({ relative, baseUrl, locale })];

  const numRooms = urlParams?.numRooms ?? urlParams?.rooms?.length ?? 1;
  const numAttendees = urlParams?.numAttendees ? urlParams?.numAttendees : 0;

  if (path === 'modify') {
    urlparts.push('modify');
  } else {
    urlparts.push('book');
  }

  urlparts.push(
    getGroupOrTransientSubpath({
      numAttendees: numAttendees ?? 0,
      numRooms: numRooms ?? 0,
      transientPath: 'reservation',
      groupsPath: 'group',
    }),
    page
  );

  return `${urlparts.join('/')}/?${bookParamBuilder(urlParams)}`;
}

export function bookParamBuilder(
  urlParams: BaseUriBuilderParams<BookUrlParams, BookPath>['urlParams']
) {
  const {
    ctyhocn,
    displayCurrency,
    adjoiningRoomStay,
    dates,
    rooms,
    rates = {},
    additionalQS = {},
    numRooms,
    numAttendees,
  } = urlParams || {};

  const arrivalDate = dates?.arrivalDate || startOfToday();
  const departureDate = dates?.departureDate || addDays(startOfToday(), 1);
  const nonEmptyAdditionalQS = Object.fromEntries(
    Object.entries(additionalQS).filter(([, v]) => Boolean(v) || v === false)
  );

  const querystringObj: BookUrlQS = {
    ctyhocn,
    arrivalDate: formatDate(arrivalDate),
    departureDate: formatDate(departureDate),
    ...rates,
    ...(numRooms == null && !numAttendees && roomsToQS(rooms)),
    ...nonEmptyAdditionalQS,
  };

  if (displayCurrency) querystringObj.displayCurrency = displayCurrency;
  if (adjoiningRoomStay) querystringObj.adjoiningRoomStay = adjoiningRoomStay;
  if (numRooms != null) querystringObj.numRooms = numRooms;
  if (numRooms == null && numAttendees) querystringObj.numRooms = 0;
  if (numAttendees) querystringObj.numAttendees = numAttendees;
  if (additionalQS.cid) querystringObj.cid = additionalQS.cid;

  return new URLSearchParams(mapParams(querystringObj)).toString();
}

// Mimic the behavior of 'querystring' encode.
function mapParams<T extends BookUrlQS>(params: T) {
  return Object.entries(params).reduce((params, [key, value]) => {
    if (typeof value === 'boolean') {
      return { ...params, [key]: String(value) };
    }
    if (typeof value === 'number') {
      return { ...params, [key]: isFinite(value) ? String(value) : '' };
    }
    if (typeof value === 'string') {
      return { ...params, [key]: value };
    }
    return { ...params, [key]: '' };
  }, {} as Record<string, string>);
}

function buildBaseUrl({
  relative,
  baseUrl,
  locale,
}: {
  relative?: boolean;
  baseUrl?: string;
  locale: string;
}) {
  return relative || (!relative && !baseUrl) ? `/${locale}` : `${baseUrl}/${locale}`;
}

function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

function roomsToQS(rooms?: Room[]) {
  if (!rooms?.length) {
    return {
      room1NumAdults: 1,
    };
  }
  if ((rooms?.length || 1) >= 10) {
    return {
      numRooms: rooms?.length,
    };
  }
  return rooms.reduce((prev, curr, index) => {
    const adults: Adult[] =
      typeof curr.adults === 'number' ? Array(curr.adults).fill({ age: null }) : curr.adults;
    const children: Child[] =
      typeof curr.children === 'number' ? Array(curr.children).fill({ age: null }) : curr.children;

    const roomNumber = index + 1;
    if (adults.length) {
      prev[`room${roomNumber}NumAdults`] = adults.length;
      const adultAges = adults
        .filter((a) => a.age !== undefined && a.age !== null)
        .map((a) => a.age);
      if (adultAges.length) {
        prev[`room${roomNumber}AdultAges`] = adultAges.join(',');
      }
    }
    if (children.length) {
      prev[`room${roomNumber}NumChildren`] = children.length;
      const childAges = children.filter((c) => c.age !== null).map((c) => c.age);
      if (childAges.length) {
        prev[`room${roomNumber}ChildAges`] = childAges.join(',');
      }
    }

    return prev;
  }, {} as Record<string, number | string>);
}
